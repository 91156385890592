import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_ADMIN_KEY,
  dangerouslyAllowBrowser: true
});

export async function GenerateTurbo(sysPrompt: string, prompt: string): Promise<string> {
  const completion = await openai.chat.completions.create({
    messages: [{role: 'system', content: sysPrompt}, { role: 'user', content: prompt }],
    model: 'gpt-3.5-turbo',
  });

  return completion.choices.at(0)!.message.content!;
}