import React, { FormEvent, useCallback, useRef, useState } from 'react';
import * as ai from '../ai/ai'

interface PostGenerateProps {
  
}

const PostGenerate:React.FC<PostGenerateProps> = () => {

  const [response, setResponse] = useState<string>();
  const [prompt, setPrompt] = useState<string>();
  const [sysPrompt, setSysPrompt] = useState<string>();

  const onSubmitCallback = (event: FormEvent) => {
    event.preventDefault();
    const formJson = Object.fromEntries(new FormData(event.target as HTMLFormElement))
    let sysPrompt = "You are a social media manager. A user will give you some data. From this data, you must create a new social media post."
    let prompt = "Platform: " + formJson.platform + "\n Product Name: " + formJson.productname + "\n Focus On: " + formJson.focus + "\n Length: " + formJson.length;
    setPrompt(prompt);
    setSysPrompt(sysPrompt);
    ai.GenerateTurbo(sysPrompt, prompt).then((response) => {
      if (!formJson.emojis) response = response.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, "");
      setResponse(response);
    })
  }

  return (
    <div>
      
      <form onSubmit={onSubmitCallback}>
        <label>product name
          <br/>
          <textarea name='productname' defaultValue="new balance fresh fit"/>
        </label>
        <br/>
        <label>focus
          <br/>
          <textarea name='focus' defaultValue="orange color"/>
        </label>
        <br/>
        <label>length
          <br/>
          <textarea name='length' defaultValue="long"/>
        </label>
        <br/>
        <label>platform
          <br/>
          <select name='platform' defaultValue={"Twitter"}>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="LinkedIn">LinkedIn</option>
        </select>
        <br/>
        </label>
        <label>emojis
          <br/>
          <input type="checkbox" name='emojis'/>
        </label>
        <br/>
        <button type='submit'>submit</button>
      </form>



      <label><b>prompt</b>
        <br/>
        {prompt ? prompt.split("\n").map(str => <p>{str}</p>) : null}
      </label>
      <br/>
      <label><b>sysprompt</b>
        <br/>
         {sysPrompt ? sysPrompt.split("\n").map(str => <p>{str}</p>) : null}
      </label>
      <br/>
      <label><b>response</b>
        <br/>
         {response ? response.split("\n").map(str => <p>{str}</p>) : null}
      </label>
    </div>
  );
}

export default PostGenerate;
