import React, { useState } from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import PostGenerate from './components/PostGenerate';
import IdeaGenerate from './components/IdeaGenerate';

const { Header, Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navIcons = [UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined];
  const navTitles = ["Post Generator", "Idea Generator", "Title 3", "Support"];

  const [selectedMenuItem, setSelectedMenuItem]= useState("1");

  const contentSwitch = (key: string) => {
    switch (key) {
      case "1":
        return (<PostGenerate/>);
      case "2":
        return (<IdeaGenerate/>);
      case "3":
        return (<div>3 not implemented</div>);
      case "4":
        return (<div>4 not implemented</div>);
      default:
        return (<div>default not implemented</div>);
    }
  }

  return (
    <Layout style={{height: "100vh"}}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={navIcons.map(
            (icon, index) => ({
              key: String(index + 1),
              icon: React.createElement(icon),
              label: navTitles[index],
            })
          )}
          onClick={(e) => setSelectedMenuItem(e.key)}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }} />
        <Content style={{ margin: '24px 16px 0' }}>
          
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>{contentSwitch(selectedMenuItem)}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Created by Payton Rogers</Footer>
      </Layout>
    </Layout>
  );
};

export default App;