import { FormEvent, useState } from "react";
import * as ai from '../ai/ai'

interface IdeaGenerateProps {
  
}

const IdeaGenerate:React.FC<IdeaGenerateProps> = () => {
    
    const [response, setResponse] = useState<string>();
  const [prompt, setPrompt] = useState<string>();
  const [sysPrompt, setSysPrompt] = useState<string>();

  const onSubmitCallback = (event: FormEvent) => {
    event.preventDefault();
    const formJson = Object.fromEntries(new FormData(event.target as HTMLFormElement))
    let sysPrompt = "You are a marketing director. You are speaking to one of your employees. Help your employee out by giving them some good ideas. The data given to you will help you understand what type of ideas they need. Give your employee 3 ideas to work with."
    let prompt = "Product Name: " + formJson.productname + "\nFocus: " + formJson.focus + "\nIdea type: " + formJson.type;
    setPrompt(prompt);
    setSysPrompt(sysPrompt);
    ai.GenerateTurbo(sysPrompt, prompt).then((response) => {
      //if (!formJson.emojis) response = response.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, "");
      setResponse(response);
    })
  }
    
    
    
    return (<>
        <form onSubmit={onSubmitCallback}>
        <label>product name
          <br/>
          <textarea name='productname' defaultValue="new balance fresh fit"/>
        </label>
        <br/>
        <label>focus
          <br/>
          <textarea name='focus' defaultValue="orange color"/>
        </label>
        <br/>
        <label>platform
          <br/>
          <select name='platform' defaultValue={"Twitter"}>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="LinkedIn">LinkedIn</option>
        </select>
        <br/>
        </label>
         <label>type
          <br/>
          <select name='type' defaultValue={"Twitter"}>
            <option value="one or more images with accompanying text post">Image(s)</option>
            <option value="short-form video with accompanying text post">Short Video</option>
            <option value="long-form video with accompanying text post">Long Video</option>
            <option value="text post without any videos or images">Text Only</option>
            <option value="shoutout to a user of the product">User Shoutout</option>
            <option value="a meme about the product">Meme</option>
        </select>
        <br/>
        </label>
        <br/>
        <button type='submit'>submit</button>
      </form>



      <label><b>prompt</b>
        <br/>
        {prompt ? prompt.split("\n").map(str => <p>{str}</p>) : null}
      </label>
      <br/>
      <label><b>sysprompt</b>
        <br/>
         {sysPrompt ? sysPrompt.split("\n").map(str => <p>{str}</p>) : null}
      </label>
      <br/>
      <label><b>response</b>
        <br/>
         {response ? response.split("\n").map(str => <p>{str}</p>) : null}
      </label>
    
    </>)
}


export default IdeaGenerate;
